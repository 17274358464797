import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue' //多式联运平台跳转页面
import tiandituView from '../views/AboutView.vue' //移动端页面
import searchView from '../views/searchView.vue' //公共服务平台嵌入页面
import noloading from '../views/noloading.vue' //公共服务平台嵌入页面的无loading，与homeview一样

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/tianditu',
      name: 'tianditu',
      component: tiandituView
    },{
      path: '/search',
      name: 'search',
      component: searchView
    },{
      path: '/noloading',
      name: 'noloading',
      component: noloading
    }
  ]
})

export default router
